<!-- 客群管理 -->
<template>
  <div class="holder">
    <div class="holder_inside">
      <div class="header">
        <div class="logo">
          <i> </i>
          <span>灯塔虚拟直播间</span>
        </div>
        <div :class="{ connect_back: true, connected: isBackConnected }"></div>
      </div>

      <div class="preview_video_holder" ref="refPreviewVideoHolder">
        <video :src="webmSrc" controls class="webm_video"></video>
        <video id="previewVideo" class="preview_video" playsinline autoplay></video>
        <div :class="{ canvas_holder: true, no_draw: !isDrawing }">
          <sign-canvas class="draw_area" ref="refDrawArea" :options="drawOptions" v-model="drawValue" />
        </div>
        <div class="draw_control">
          <el-switch class="c_draw" v-model="isDrawing" inactive-color="#ccc" active-text="绘制中" inactive-text="">
          </el-switch>
          <ul class="c_color">
            <li v-for="(item, index) in colorArr" :key="index" :class="{ on: index == currentColorIndex }"
              :style="`background:${item}`" @click="changeColor(index)"></li>
          </ul>
          <el-button type="primary" plain @click="clearDrawArea">清空</el-button>
        </div>
      </div>

      <div class="content">
        <h1>obs 控制区</h1>
        <div class="row">
          <div class="col">
            <h2>切配置文件</h2>
            <ul class="scene_list">
              <li :class="{ on: item.isCurrent }" v-for="(item, index) in profileList" :key="index"
                @click="switchProfile(item['profile-name'])">
                {{ item["profile-name"] }}
              </li>
            </ul>
            <h2>切场景</h2>
            <ul class="scene_list">
              <li v-for="(item, index) in sceneList" :key="index" :class="{ on: item.isCurrent }"
                @click="switchScene(item.name)">
                {{ item.name }}
              </li>
            </ul>
            <div :class="{ btn_normal: true, on: isRecording }" @click="toggleRecord()">
              {{ isRecording ? "停止录制" : "开始录制" }}
            </div>
            <div :class="{ btn_normal: true, on: isVirtualing }" @click="toggleVirtual()">
              {{ isVirtualing ? "停止虚拟摄像机" : "开启虚拟摄像机" }}
            </div>
            <div class="btn_normal" @click="obsControlTest()">GetSceneItemList</div>
            <div class="btn_normal" @click="obsControlTest2()">GetSceneItemProperties</div>
            <div class="btn_normal" @click="obsControlTest3()">GetSourceFilters</div>
            <div class="btn_normal" @click="obsControlTest4()">GetSourceSettings</div>
            <div class="btn_normal" @click="obsControlTest5()">刷新浏览器RefreshBrowserSource</div>
          </div>
        </div>

        <h1>自动区</h1>
        <div class="row">
          <div class="col">
            <ul class="list">
              <li :class="{ on: autoIndex == index }" v-for="(item, index) in autoList" :key="index">
                {{ item.name }}
              </li>
            </ul>
            <div class="btn_next" @click="nextAction()">我切</div>
            <div class="btn_reset" @click="reset()">重置</div>
            <div class="btn_toggle" @click="togglePc()">
              {{ isShowPc ? "隐藏pc桌面" : "显示pc桌面" }}
            </div>
          </div>
        </div>

        <h1>手动区</h1>
        <div class="row">
          <div class="col">
            <h2>横竖屏</h2>
            <ul class="list with_hover">
              <li :class="{ on: activeScreenModeName == item.name }" v-for="(item, index) in screenModeList"
                :key="index">
                <b @click="setScreenMode(item, 'show')">{{ item.name }}</b>
              </li>
            </ul>

            <h2>直播间背景</h2>
            <ul class="list with_hover">
              <li :class="{ on: activeBroadcastBgMediaName == item.name }" v-for="(item, index) in broadcastBgMediaList"
                :key="index">
                <b @click="setBroadcastBgMedia(item, 'show')">{{ item.name }}</b>
              </li>
            </ul>
            <h2>直播间logo</h2>
            <ul class="list with_hover">
              <li :class="{ on: activeLogoName == item.name }" v-for="(item, index) in logoList" :key="index">
                <b @click="setLogo(item, 'show')">{{ item.name }}</b>
                <span @click="setLogo(item, 'hide')">关闭</span>
              </li>
            </ul>

            <h2>底部条</h2>
            <ul class="list with_hover">
              <li :class="{ on: activeMultiFuncBarName == item.name }" v-for="(item, index) in multiFuncBarList"
                :key="index">
                <b @click="setMultiFuncBar(item, 'show')">{{ item.name }}</b>
                <span @click="setMultiFuncBar(item, 'hide')">关闭</span>
              </li>
            </ul>

            <ul class="list with_hover">
              <li :class="{ on: activeMarqueeName == item.name }" v-for="(item, index) in marqueeList" :key="index">
                <b @click="setMarquee(item, 'show')">{{ item.name }}</b>
                <span @click="setMarquee(item, 'hide')">关闭</span>
              </li>
            </ul>

            <!-- <h2>底部风险警示滚动</h2>
            <ul class="list with_hover">
              <li
                :class="{ on: activeTextMarqueeName == item.name }"
                v-for="(item, index) in textMarqueeList"
                :key="index"
              >
                <b @click="setTextMarquee(item, 'show')">{{ item.name }}</b>
                <span @click="setTextMarquee(item, 'hide')">关闭</span>
              </li>
            </ul> -->

            <h2>全屏特效</h2>
            <ul class="list with_hover">
              <li :class="{ on: activeEffectName == item.name }" v-for="(item, index) in effectList" :key="index">
                <b @click="setEffect(item, 'show')">{{ item.name }}</b>
                <span @click="setEffect(item, 'hide')">关闭</span>
              </li>
            </ul>
          </div>

          <div class="col">
            <h2>窗口-个性化图表</h2>
            <div class="blocks_holder">
              <div :class="{ block: true, on: windowCustomChartToggle[index] == true }"
                v-for="(item, index) in windowCustomChartList" :key="index">
                <div class="block_title" @click="toggleBlock(index)">{{ windowCustomChartListTitles[index] }}</div>
                <ul class="list with_hover">
                  <li v-for="(cell, cIndex) in item" :key="cIndex"
                    :class="{ on: activeWindowCustomChartName == cell.name }">
                    <b @click.stop="setCustomWindowChart(cell, 'show')">{{ cell.name }}</b>
                    <span @click.stop="setCustomWindowChart(cell, 'hide')">关闭</span>
                    <i v-if="cell.isLoading == true">loading</i>
                  </li>
                </ul>
              </div>
            </div>

            <div class="sub_row">
              <div class="sub_col">
                <h2>窗口-通用图表</h2>
                <ul class="list with_hover">
                  <li :class="{ on: activeWindowGeneralChartName == item.name }"
                    v-for="(item, index) in windowGeneralChartList" :key="index">
                    <b @click="setGeneralWindowChart(item, 'show')">{{ item.name }}</b>
                    <span @click="setGeneralWindowChart(item, 'hide')">关闭</span>
                    <i v-if="item.isLoading == true">loading</i>
                  </li>
                </ul>
              </div>
              <div class="sub_col">
                <h2>窗口-图片视频</h2>
                <ul class="list with_hover">
                  <li :class="{ on: activeWindowMediaName == item.name }" v-for="(item, index) in windowMediaList"
                    :key="index">
                    <b @click="setWindowMedia(item, 'show')">{{ item.name }}</b>
                    <span @click="setWindowMedia(item, 'hide')">关闭</span>
                  </li>
                </ul>
              </div>
              <div class="sub_col">
                <h2>窗口-iframe新闻</h2>
                <ul class="list with_hover">
                  <li :class="{ on: activeWindowIframeName == item.name }" v-for="(item, index) in iframeList"
                    :key="index">
                    <b @click="setWindowIframe(item, 'show')">{{ item.name }}</b>
                    <span @click="setWindowIframe(item, 'hide')">关闭</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer" id="footer">copyright 灯塔财经 荣誉出品</div>
    </div>
  </div>
</template>

<script>
import OBSWebSocket, { EventSubscription } from "obs-websocket-js";
const obs = new OBSWebSocket();
import { sleep, convertChartData } from "@/utils/utils.js";
import axios from "axios";

//导入画图部分
import SignCanvas from "sign-canvas";

//控制端配置
import { marketChart } from "@/businessComp/charts/marketChart/marketChart.json";
import { northFundChart } from "@/businessComp/charts/northFundChart/northFundChart.json";
import { auctionChart } from "@/businessComp/charts/auctionChart/auctionChart.json";
import { financeCompareChart } from "@/businessComp/charts/financeCompareChart/financeCompareChart.json";
import { diagnosisValueChart } from "@/businessComp/charts/diagnosisValueChart/diagnosisValueChart.json";
import { financeAnalysisChart } from "@/businessComp/charts/financeAnalysisChart/financeAnalysisChart.json";
import { evaluatingDistChart } from "@/businessComp/charts/evaluatingDistChart/evaluatingDistChart.json";
import { marginTradeChart } from "@/businessComp/charts/marginTradeChart/marginTradeChart.json";
import { longHuChart } from "@/businessComp/charts/longHuChart/longHuChart.json";
import { ipoInfoChart } from "@/businessComp/charts/ipoInfoChart/ipoInfoChart.json";
import { checkMarketChart } from "@/businessComp/charts/checkMarketChart/checkMarketChart.json";
import { bigTradeChart } from "@/businessComp/charts/bigTradeChart/bigTradeChart.json";
import { chipDistChart } from "@/businessComp/charts/chipDistChart/chipDistChart.json";
import { resultsContrastChart } from "@/businessComp/charts/resultsContrastChart/resultsContrastChart.json"
import { companyIntroChart } from "@/businessComp/charts/companyIntroChart/companyIntroChart.json";
import { windowGeneralChartList } from "@/businessComp/charts/generalChart/windowGeneralChartList.json";
import { plateAreaChart } from "@/businessComp/charts/plateAreaChart/plateAreaChart.json";
import { intelligentDiagnosisChart } from "@/businessComp/charts/intelligentDiagnosisChart/intelligentDiagnosisChart.json";
import { investmentMapChart } from "@/businessComp/charts/investmentMapChart/investmentMapChart.json";
import { duPonChart } from "@/businessComp/charts/duPonChart/duPonChart.json";
import { stocksFundsChart } from "@/businessComp/charts/stocksFundsChart/stocksFundsChart.json";
import { sharePledgeChart } from "@/businessComp/charts/sharePledgeChart/sharePledgeChart.json";
import { bsSignalChart } from "@/businessComp/charts/bsSignalChart/bsSignalChart.json";
import { keChuangGraphChart } from "@/businessComp/charts/keChuangGraphChart/keChuangGraphChart.json";
import { plateQuoteChart } from "@/businessComp/charts/plateQuoteChart/plateQuoteChart.json";
import { nuggetsChart } from "@/businessComp/charts/nuggetsChart/nuggetsChart.json";
import { stockMineSweepChart } from "@/businessComp/charts/stockMineSweepChart/stockMineSweepChart.json";
import { financingTrackChart } from "@/businessComp/charts/financingTrackChart/financingTrackChart.json";
import { goodwillAnalysisChart } from "@/businessComp/charts/goodwillAnalysisChart/goodwillAnalysisChart.json";
import { goodwillWarnChart } from "@/businessComp/charts/goodwillWarnChart/goodwillWarnChart.json";
import { historyBackChart } from "@/businessComp/charts/historyBackChart/historyBackChart.json";
import { longHuStockChart } from "@/businessComp/charts/longHuStockChart/longHuStockChart.json";
import { similarKChart } from "@/businessComp/charts/similarKChart/similarKChart.json";
import { intelliChooseStockChart } from "@/businessComp/charts/intelliChooseStockChart/intelliChooseStockChart.json";

//获取数据层
import { getMarketChartData } from "@/businessComp/charts/marketChart/marketChartData.js";
import { getPlateChartData } from "@/businessComp/charts/marketChart/plateChartData.js";
import { getNorthFundChartData } from "@/businessComp/charts/northFundChart/northFundFlowChartData.js";
import { getAuctionChartData } from "@/businessComp/charts/auctionChart/auctionChartData.js";
import { getFinanceCompareData } from "@/businessComp/charts/financeCompareChart/financeCompareData.js";
import { getDiagnosisValueData } from "@/businessComp/charts/diagnosisValueChart/diagnosisValueData.js";
import { getFinanceAnalysisData } from "@/businessComp/charts/financeAnalysisChart/financeAnalysisData.js";
import { getEvaluatingDistChartData } from "@/businessComp/charts/evaluatingDistChart/evaluatingDistData.js";
import { getmarginTradeData } from "@/businessComp/charts/marginTradeChart/marginTradeData.js";
import { getLongHuData } from "@/businessComp/charts/longHuChart/longHuData.js";
import { getIpoInfoData } from "@/businessComp/charts/ipoInfoChart/ipoInfoChart.js";
import { getCheckMarketData } from "@/businessComp/charts/checkMarketChart/checkMarketData.js";
import { getBigTradeData } from "@/businessComp/charts/bigTradeChart/bigTradeData.js";
import { getChipDistChartData } from "@/businessComp/charts/chipDistChart/chipDistData.js";
import { getResultsContrastData } from "@/businessComp/charts/resultsContrastChart/resultsContrastData.js"
import { getCompanyIntroData } from "@/businessComp/charts/companyIntroChart/companyIntroData.js";
import { getPlateAreaData } from "@/businessComp/charts/plateAreaChart/plateAreaData.js";
import { getIntelligentDiagnosisData } from "@/businessComp/charts/intelligentDiagnosisChart/intelligentDiagnosisData.js";
import { getInvestmentMapData } from "@/businessComp/charts/investmentMapChart/investmentMapData.js";
import { getDuPonData } from "@/businessComp/charts/duPonChart/duPonData.js";
import { getStocksFundsData } from "@/businessComp/charts/stocksFundsChart/stocksFundsData.js";
import { getSharePledgeData } from "@/businessComp/charts/sharePledgeChart/sharePledgeData.js";
import { getBsSignalData } from "@/businessComp/charts/bsSignalChart/bsSignalData.js";
import { getKeChuangGraphData } from "@/businessComp/charts/keChuangGraphChart/keChuangGraphData.js";
import { getPlateQuoteData } from "@/businessComp/charts/plateQuoteChart/plateQuoteData.js";
import { getNuggetsData } from "@/businessComp/charts/nuggetsChart/nuggetsData.js";
import { getStockMineSweepData } from "@/businessComp/charts/stockMineSweepChart/stockMineSweepData.js";
import { getFinancingTrackData } from "@/businessComp/charts/financingTrackChart/financingTrackData.js";
import { getGoodwillAnalysisData } from "@/businessComp/charts/goodwillAnalysisChart/goodwillAnalysisData.js";
import { getGoodwillWarnData } from "@/businessComp/charts/goodwillWarnChart/goodwillWarnData.js";
import { getHistoryBackData } from "@/businessComp/charts/historyBackChart/historyBackData.js";
import { getLongHuStockData } from "@/businessComp/charts/longHuStockChart/longHuStockData.js";
import { getSimilarKData } from "@/businessComp/charts/similarKChart/similarKData.js";
import { getIntelliChooseStockData } from "@/businessComp/charts/intelliChooseStockChart/intelliChooseStockData.js";

const functions = {
  getPlateChartData,
  getNorthFundChartData, //北上资金系列
  getAuctionChartData, //集合竞价系列
  getFinanceCompareData, //财报对比系列
  getDiagnosisValueData, //诊断-价值系列
  getFinanceAnalysisData, //财报-业绩系列
  getEvaluatingDistChartData, //估值系列
  getmarginTradeData, // 融资融券
  getLongHuData, // 龙虎榜
  getIpoInfoData, //新股中心
  getCheckMarketData, // 市场强度
  getBigTradeData, //大宗交易
  getChipDistChartData, //筹码分布
  getResultsContrastData, // 财报对比
  getCompanyIntroData,//个股F10
  getPlateAreaData, // 板块地域概念
  getIntelligentDiagnosisData, // 个股诊断
  getInvestmentMapData, // 投资图谱
  getDuPonData, // 杜邦分析
  getStocksFundsData, // 个股资金
  getSharePledgeData, // 股权质押
  getBsSignalData,//多空信号
  getKeChuangGraphData,//科创掘金
  getPlateQuoteData,//榜单
  getNuggetsData,
  getStockMineSweepData,//扫雷
  getFinancingTrackData, // 融资追击

  getGoodwillAnalysisData, // 商誉分析大盘
  getGoodwillWarnData,//商誉分析(个股)
  getHistoryBackData, // 历史回看
  getLongHuStockData, // 龙虎榜个股
  getSimilarKData,//相似K线
  getIntelliChooseStockData,//策略选股
};

let chatSocket;
export default {
  components: { SignCanvas },
  data() {
    return {
      userId: 0, //用户id

      webmSrc: require("../assets/output.webm"),
      isDrawing: false,
      currentColorIndex: 0,
      colorArr: [
        "rgb(235, 245, 36)",
        "rgb(128, 211, 158)",
        "rgb(139, 109, 40)",
        "rgb(226, 152, 37)",
        "rgb(234, 86, 32)",
        "rgb(46, 36, 81)",
        "rgb(85, 201, 26)",
      ],

      repeatTimestamp: 0,
      isReceiveConnected: false, //背景第一次连接
      isBackConnected: false, //是否已连接背景
      backAskGap: 2 * 1000, //心跳间隔。未连接成功前默认会快一些，连接成功后心跳变慢
      profileList: [], //配置文件列表
      sceneList: [], //场景列表
      isRecording: false, //默认未开始录像
      isVirtualing: false, //默认未开启虚拟摄像机

      drawValue: null,
      drawOptions: {
        isDpr: true, //是否使用dpr兼容高倍屏 [Boolean] 可选
        lastWriteSpeed: 1, //书写速度 [Number] 可选
        lastWriteWidth: 5000, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
        lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 10, //canvas宽高 [Number] 可选
        canvasHeight: 10, //高度  [Number] 可选
        isShowBorder: false, //是否显示边框 [可选]
        bgColor: "transparent", //背景色 [String] 可选
        borderWidth: 0, // 网格线宽度  [Number] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        writeColor: "#101010", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        // imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },

      autoList: [
        {
          name: "横屏",
          componentName: "screenMode",
          componentCommandData: "landscape",
        },
        {
          name: "墙纸",
          componentName: "broadcastBgMedia",
          componentCommand: "show",
          componentCommandData: require("../assets/videoBg/static1.jpg"),
          isThemeBlack: true,
        },
        {
          name: "招商",
          componentName: "logoSwitcher",
          componentCommand: "show",
          componentCommandData: require("../assets/logos/logo.png"),
        },
        {
          name: "上证指数日K线",
          componentName: "chartSwitcher|marketChart",
          componentCommand: "show",
          componentCommandData: {
            seccode: "0105000001",
            secname: "上证指数",
            chartType: "日K",
          },
        },
        {
          name: "上证指数日K线-hide",
          componentName: "chartSwitcher|marketChart",
          componentCommand: "hide",
        },
        {
          name: "指数涨跌幅",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "show",
          componentCommandData: "https://dataapishutu.wedengta.com/cube/chartdata/指数涨跌幅/",
        },
        {
          name: "指数涨跌幅-hide",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "hide",
        },
        {
          name: "板块涨跌幅",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "show",
          componentCommandData: "https://dataapishutu.wedengta.com/cube/chartdata/板块涨跌幅/",
        },
        {
          name: "新能源车PE",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "show",
          componentCommandData: "https://dataapishutu.wedengta.com/cube/chartdata/新能源车PE/",
        },
        {
          name: "光伏产业PE",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "show",
          componentCommandData: "https://dataapishutu.wedengta.com/cube/chartdata/光伏产业PE/",
        },
        {
          name: "上证指数周K线-hide",
          componentName: "chartSwitcher|marketChart",
          componentCommand: "hide",
        },
        {
          name: "低估值板块方块图",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "show",
          componentCommandData: "https://dataapishutu.wedengta.com/cube/chartdata/低估值板块方块图/",
        },
        {
          name: "低估值板块方块图",
          componentName: "chartSwitcher|generalChart",
          componentCommand: "hide",
        },
        {
          name: "金字塔-加仓",
          componentName: "windowMedia",
          componentCommand: "show",
          componentCommandData: require("../assets/videoFront/pyramidAdd.mp4"),
        },
        {
          name: "金字塔-减仓",
          componentName: "windowMedia",
          componentCommand: "show",
          componentCommandData: require("../assets/videoFront/pyramidMinus.mp4"),
        },
        {
          name: "金字塔-减仓",
          componentName: "windowMedia",
          componentCommand: "hide",
        },
      ],

      screenModeList: [
        {
          name: "横屏",
          componentName: "screenMode",
          componentCommandData: "landscape",
        },
        {
          name: "竖屏",
          componentName: "screenMode",
          componentCommandData: "portrait",
        },
      ],
      activeScreenModeName: "landscape", //默认横屏
      broadcastBgMediaList: [
        {
          name: "红色地球 视频",
          componentName: "broadcastBgMedia",
          componentCommandData: require("../assets/videoBg/bg1.mp4"),
          isThemeBlack: false,
        },
        {
          name: "图书馆1",
          componentName: "broadcastBgMedia",
          componentCommandData: require("../assets/videoBg/static1.jpg"),
          isThemeBlack: false,
        },
        {
          name: "透明",
          componentName: "broadcastBgMedia",
          componentCommandData: require("../assets/videoBg/transparent.png"),
          isThemeBlack: true,
        },
      ],
      activeBroadcastBgMediaName: "图书馆1",

      logoList: [
        {
          name: "山寨logo",
          componentName: "logoSwitcher",
          componentCommandData: require("../assets/logos/logo.png"),
        },
      ],
      activeLogoName: "",

      multiFuncBarList: [
        {
          name: "底部综合展示栏皮肤1",
          componentName: "multiFuncBar",
          componentCommandData: {
            theme: "theme1", //皮肤1
            title: "低位布局  掘金中证500", //直播间大标题
            logo: require("../assets/logos/logo.png"), //直播间logo
            // liveTime: "", //直播时间
            liveTime: "[直播时间] 交易日 15:50-15:45", //直播时间
            liveDate: "2022.06.27", //直播日期
            riskFree: "本内容仅供参考，不构成投资建议。股市有风险，投资需谨慎。", //风险提示
            marqueeText:
              "过往业绩不预示未来表现。市场有风险，投资需谨慎 　　　　　投资顾问 吴丽娜，执业证书编号：S0850620080049　　　　　", //投顾老师id
          },
        },
        {
          name: "底部综合展示栏皮肤2",
          componentName: "multiFuncBar",
          componentCommandData: {
            theme: "theme2", //皮肤2
            title: "低位布局  掘金中证500", //直播间大标题
            logo: require("../assets/logos/logo.png"), //直播间logo
            // liveTime: "", //直播时间
            liveTime: "[直播时间] 交易日 15:50-15:45", //直播时间
            liveDate: "2022.06.27", //直播日期
            riskFree: "本内容仅供参考，不构成投资建议。股市有风险，投资需谨慎。", //风险提示
            marqueeText:
              "过往业绩不预示未来表现。市场有风险，投资需谨慎 　　　　　投资顾问 吴丽娜，执业证书编号：S0850620080049　　　　　", //投顾老师id
          },
        },
      ],
      activeMultiFuncBarName: "",

      marqueeList: [
        {
          name: "实时市场数据滚动条皮肤1",
          componentName: "marketMarquee",
          componentCommandData: {
            theme: "theme1",
            barTitle: "今日收盘",
            barRiskFree: "投资有风险，入市需谨慎。",
            barCareerNo: "欧阳静静 执业编号S0950616090005",
            time: "上午|11:15",
          },
        },
        {
          name: "实时市场数据滚动条皮肤2",
          componentName: "marketMarquee",
          componentCommandData: {
            theme: "theme2",
            barTitle: "今日收盘",
            barRiskFree: "投资有风险，入市需谨慎。",
            barCareerNo: "欧阳静静 执业编号S0950616090005",
            time: "上午|11:15",
          },
        },
      ],
      activeMarqueeName: "",

      textMarqueeList: [
        {
          name: "风险警示条",
          componentName: "textMarquee",
          componentCommand: "show",
          componentCommandData: {
            title: "本周回顾",
            subTitle: "本周回顾",
            marqueeTxt: "本周回顾,本周回顾本周,回顾本周回顾本周回顾,本周回顾,本周回顾",
          },
        },
      ],
      activeTextMarqueeName: "",

      effectList: [
        {
          name: "下红包",
          componentName: "snowFlakes",
          componentCommandData: 1,
        },
        {
          name: "下元宝",
          componentName: "snowFlakes",
          componentCommandData: 2,
        },
        {
          name: "下元宝2",
          componentName: "snowFlakes",
          componentCommandData: 3,
        },
        {
          name: "下雪",
          componentName: "snowFlakes",
          componentCommandData: 4,
        },
        {
          name: "下狗头",
          componentName: "snowFlakes",
          componentCommandData: 5,
        },
      ],
      activeEffectName: "",

      fullMediaList: [
        {
          name: "上证",
          componentName: "fullMedia",
          componentCommandData: require("../assets/imageBg/bg1.png"),
          isThemeBlack: true,
        },
        {
          name: "乌克兰",
          componentName: "fullMedia",
          componentCommandData:
            "https://th.bing.com/th/id/R.bb7776b611517e209b34fbe0214f7c6c?rik=2VK3%2b6JZowLFYA&riu=http%3a%2f%2fwww.yun519.com%2fuploads%2f201206%2f1339462592ndVkwsHQ.jpg&ehk=1WdmRbShJroVaQm%2fP17Oy9roCwmLsEZyA1%2byeYg8Qe4%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1",
          isThemeBlack: false,
        },
        {
          name: "战争",
          componentName: "fullMedia",
          componentCommandData:
            "https://th.bing.com/th/id/R.b665482e46dea24722c36fb92c6da39b?rik=KT6bd0bK%2fEtZyA&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20190409%2fcfafaced7ec34941ba3beaef455a4ab5.jpeg&ehk=0jn8bwKAnOh5VCfJPja6wlJWynQ2XcA%2blounzJ8DG6w%3d&risl=&pid=ImgRaw&r=0",
          isThemeBlack: true,
        },
      ],
      activeFullMediaName: "",

      windowCustomChartList: [],
      windowCustomChartToggle: [], //toggle列表
      windowCustomChartListTitles: [],
      activeWindowCustomChartName: "",

      windowGeneralChartList: [],
      activeWindowGeneralChartName: "",

      windowMediaList: [
        {
          name: "中证500",
          componentName: "windowMedia",
          componentCommandData: require("../assets/imageFront/zz500.png"),
        },
        {
          name: "熊猫骑马gif",
          componentName: "windowMedia",
          componentCommandData: "https://img-blog.csdnimg.cn/20210316164458567.gif",
        },
        {
          name: "金字塔-加仓",
          componentName: "windowMedia",
          componentCommandData: require("../assets/videoFront/pyramidAdd.mp4"),
        },
        {
          name: "金字塔-减仓",
          componentName: "windowMedia",
          componentCommandData: require("../assets/videoFront/pyramidMinus.mp4"),
        },
      ],
      activeWindowMediaName: "",

      iframeList: [
        {
          name: "沃森生物1",
          componentName: "windowIframe",
          componentCommandData:
            "http://192.168.51.51:7658/news?id=1648696506_7081097579634652308_11_1&dt_from=web&type=1&consoleMode=0",
        },
        {
          name: "沃森生物2",
          componentName: "windowIframe",
          componentCommandData:
            "http://192.168.51.51:7658/news?id=1649389173_8707754_1_9&dt_from=web&type=4&consoleMode=0",
        },
        {
          name: "沃森生物3",
          componentName: "windowIframe",
          componentCommandData:
            "http://192.168.51.51:7658/news?id=1648656076_690554247183_402_7&dt_from=web&type=3&isPdfConvert=1&dt_sbt=2&consoleMode=0",
        },
      ],
      activeWindowIframeName: "",

      autoIndex: -1,
      isShowPc: false,
      isRefreshingTime: false, //默认没有在刷新分时
      timeRefreshTimer: null, //分时轮询的handler
    };
  },
  watch: {
    isDrawing: function (val, oldVal) {
      // console.log("isDrawing", val, oldVal);
      this.clearDrawArea();
    },
    drawValue: function (val, oldVal) {
      console.log("val", val);
      this.sendCommand("fullMedia", "show", val == null ? "" : val);
    },
  },
  methods: {
    clearDrawArea() {
      this.$refs.refDrawArea.canvasClear();
    },
    changeColor(index) {
      this.currentColorIndex = index;
      this.drawOptions.writeColor = this.colorArr[index];
    },
    toggleBlock(index) {
      this.$set(this.windowCustomChartToggle, index, this.windowCustomChartToggle[index] ? false : true);
      console.log(this.windowCustomChartToggle);
    },
    createSocket() {
      const path = "wss://ws.wedengta.com/ws/livestream/";
      try {
        chatSocket = new WebSocket(path + this.userId + "/");
      } catch (e) {
        // Notify({ type: "danger", message: "长连接建立失败！" });
      }
      chatSocket.onopen = (e) => {
        //通知接收端已连接
        this.repeatAskFirst();
        //开始接收
        this.startMonite();
      };
      chatSocket.onerror = (e) => {
        // Notify({ type: "danger", message: "长连接出错" });
      };
    },
    //重复询问接收端是否上线
    async repeatAskFirst() {
      if (this.isReceiveConnected) {
        return;
      }
      this.sendCommand("firstAsk");
      await sleep(this.backAskGap);
      this.repeatAskFirst();
    },
    //只要上线了，就间隔几秒询问一次背景是否还活着
    async repeatAskBackOnline(timestamp) {
      if (this.repeatTimestamp != timestamp) {
        return;
      }
      this.sendCommand("isBackOnline");
      await sleep(this.backAskGap);
      this.repeatAskBackOnline(timestamp);
    },
    reset() {
      this.setAuto(this.autoList[this.autoIndex], "hide");
      this.autoIndex = -1;
    },
    togglePc() {
      this.sendCommand("togglePc", "", this.isShowPc ? "hidePc" : "showPc");
      this.isShowPc = !this.isShowPc;
    },
    //无脑切下一个项目
    nextAction() {
      if (this.autoIndex + 1 >= this.autoList.length) {
        return;
      }
      this.autoIndex += 1;
      this.setAuto(this.autoList[this.autoIndex], this.autoList[this.autoIndex].componentCommand);
    },
    setAuto(item, command) {
      // console.log("item", item);
      if (item.componentName.indexOf("chartSwitcher") > -1) {
        if (item.componentName.indexOf("generalChart") > -1) {
          this.setGeneralWindowChart(item, command);
        } else {
          this.setCustomWindowChart(item, command);
        }
      } else if (item.componentName.indexOf("windowMedia") > -1) {
        this.setWindowMedia(item, command);
      } else if (item.componentName.indexOf("broadcastBgMedia") > -1) {
        this.setBroadcastBgMedia(item, command);
      } else if (item.componentName.indexOf("screenMode") > -1) {
        this.setScreenMode(item, command);
      } else if (item.componentName.indexOf("logoSwitcher") > -1) {
        this.setLogo(item, command);
      }
    },
    //设置直播间背景
    async setScreenMode(item, componentCommand) {
      this.activeScreenModeName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
    },
    //设置直播间背景
    async setBroadcastBgMedia(item, componentCommand) {
      this.activeBroadcastBgMediaName = item.name;
      this.sendCommand("theme", "", item.isThemeBlack);
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
    },
    //设置logo
    async setLogo(item, componentCommand) {
      this.activeLogoName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeLogoName = "";
      }
    },
    //设置marquee
    async setMarquee(item, componentCommand) {
      this.activeMarqueeName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeMarqueeName = "";
      }
    },
    //设置marquee
    async setMultiFuncBar(item, componentCommand) {
      this.activeMultiFuncBarName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeMultiFuncBarName = "";
      }
    },
    //设置marquee
    async setTextMarquee(item, componentCommand) {
      this.activeTextMarqueeName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeTextMarqueeName = "";
      }
    },
    //设置全屏特效
    async setEffect(item, componentCommand) {
      this.activeEffectName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeEffectName = "";
      }
    },
    //设置当前window 个性化图表
    async setCustomWindowChart(item, componentCommand) {
      await this.otherWindowClose();
      this.activeWindowCustomChartName = item.name;
      if (componentCommand != "hide") {
        //如果发现是行情，就需要先等行情回来，再发送命令
        if (item.componentName == "chartSwitcher|marketChart") {
          //行情
          clearTimeout(this.timeRefreshTimer);
          this.handleMarketChart(item);
        } else if (item.componentName == "chartSwitcher|plateChart") {
          this.handleCommon(item, "getPlateChartData");
        } else if (item.componentName == "chartSwitcher|northFundChart") {
          this.handleCommon(item, "getNorthFundChartData");
        } else if (item.componentName == "chartSwitcher|auctionChart") {
          this.handleCommon(item, "getAuctionChartData");
        } else if (item.componentName == "chartSwitcher|financeCompareChart") {
          this.handleCommon(item, "getFinanceCompareData");
        } else if (item.componentName == "chartSwitcher|marginTradeChart") {
          this.handleCommon(item, "getmarginTradeData");
        } else if (item.componentName == "chartSwitcher|longHuChart") {
          this.handleCommon(item, "getLongHuData");
        } else if (item.componentName == "chartSwitcher|checkMarketChart") {
          this.handleCommon(item, "getCheckMarketData");
        } else if (item.componentName == "chartSwitcher|diagnosisValueChart") {
          this.handleCommon(item, "getDiagnosisValueData");
        } else if (item.componentName == "chartSwitcher|financeAnalysisChart") {
          this.handleCommon(item, "getFinanceAnalysisData");
        } else if (item.componentName == "chartSwitcher|evaluatingDistChart") {
          this.handleCommon(item, "getEvaluatingDistChartData");
        } else if (item.componentName == "chartSwitcher|chipDistChart") {
          this.handleCommon(item, "getChipDistChartData");
        } else if (item.componentName == "chartSwitcher|ipoInfoChart") {
          this.handleCommon(item, "getIpoInfoData");
        } else if (item.componentName == "chartSwitcher|resultsContrastChart") {
          this.handleCommon(item, "getResultsContrastData");
        } else if (item.componentName == "chartSwitcher|bigTradeChart") {
          this.handleCommon(item, "getBigTradeData");
        } else if (item.componentName == "chartSwitcher|intelligentDiagnosisChart") {
          this.handleCommon(item, "getIntelligentDiagnosisData");
        } else if (item.componentName == "chartSwitcher|investmentMapChart") {
          this.handleCommon(item, "getInvestmentMapData");
        } else if (item.componentName == "chartSwitcher|companyIntroChart") {
          this.handleCommon(item, "getCompanyIntroData");
        } else if (item.componentName == "chartSwitcher|plateAreaChart") {
          this.handleCommon(item, "getPlateAreaData");
        }  else if (item.componentName == "chartSwitcher|duPonChart") {
          this.handleCommon(item, "getDuPonData");
        } else if (item.componentName == "chartSwitcher|stocksFundsChart") {
          this.handleCommon(item, "getStocksFundsData");
        } else if (item.componentName == "chartSwitcher|sharePledgeChart") {
          this.handleCommon(item, "getSharePledgeData");
        } else if (item.componentName == "chartSwitcher|bsSignalChart") {
          //多空行情
          clearTimeout(this.timeRefreshTimer);
          this.handleBsMarketChart(item);
        } else if (item.componentName == "chartSwitcher|keChuangGraphChart") {
          this.handleCommon(item, "getKeChuangGraphData");
        } else if (item.componentName == "chartSwitcher|plateQuoteChart") {
          this.handleCommon(item, "getPlateQuoteData");
        } else if (item.componentName == "chartSwitcher|nuggetsChart") {
          this.handleCommon(item, "getNuggetsData");
        } else if (item.componentName == "chartSwitcher|stockMineSweepChart") {
          this.handleCommon(item, "getStockMineSweepData");
        } else if (item.componentName == "chartSwitcher|financingTrackChart") {
          this.handleCommon(item, "getFinancingTrackData")
        } else if (item.componentName == "chartSwitcher|goodwillAnalysisChart") {
          this.handleCommon(item, "getGoodwillAnalysisData")
        } else if (item.componentName == "chartSwitcher|goodwillWarnChart") {
          this.handleCommon(item, "getGoodwillWarnData")
        } else if (item.componentName == "chartSwitcher|historyBackChart") {
          this.handleCommon(item, "getHistoryBackData")
        } else if (item.componentName == "chartSwitcher|longHuStockChart") {
          this.handleCommon(item, "getLongHuStockData")
        } else if (item.componentName == "chartSwitcher|similarKChart") {
          this.handleCommon(item, "getSimilarKData")
        } else if (item.componentName == "chartSwitcher|intelliChooseStockChart") {
          this.handleCommon(item, "getIntelliChooseStockData")
        } else {
          this.sendCommand(item.componentName, item.componentCommand, item.componentCommandData);
        }
      } else {
        this.sendCommand(item.componentName, "hide");
        this.activeWindowCustomChartName = "";
        clearTimeout(this.timeRefreshTimer);
      }
    },

    //处理行情
    async handleMarketChart(item, isRefresh) {
      this.$set(item, "isLoading", true);
      item.componentCommandData.data = await getMarketChartData(item.componentCommandData);
      this.$set(item, "isLoading", false);
      this.sendCommand(item.componentName, item.componentCommand, {
        ...item.componentCommandData,
        isRefresh: isRefresh,
      });
      if (item.componentCommandData.chartType == "分时") {
        this.timeRefreshTimer = setTimeout(() => {
          this.handleMarketChart(item, true);
          console.log("分时调起了");
        }, 100 * 1000);
      }
    },

    //通用handle
    async handleCommon(item, functionName) {
      this.$set(item, "isLoading", true);
      item.componentCommandData.data = await functions[functionName](item.componentCommandData);
      this.$set(item, "isLoading", false);
      this.sendCommand(item.componentName, item.componentCommand, {
        ...item.componentCommandData,
      });
    },

    //处理多空信号的行情
    async handleBsMarketChart(item, isRefresh) {
      this.$set(item, "isLoading", true);
      item.componentCommandData.data = await getBsSignalData(item.componentCommandData);
      this.$set(item, "isLoading", false);
      this.sendCommand(item.componentName, item.componentCommand, {
        ...item.componentCommandData,
        isRefresh: isRefresh,
      });
      if (item.componentCommandData.type.indexOf("盘中")>-1) {
        this.timeRefreshTimer = setTimeout(() => {
          this.handleBsMarketChart(item, true);
          console.log("盘中分时调起了");
        }, 100 * 1000);
      }
    },

    //设置当前window 个性化图表
    async setGeneralWindowChart(item, componentCommand) {
      console.log("componentCommand", componentCommand);
      let commandData = "";
      if (componentCommand == "show") {
        this.$set(item, "isLoading", true);
        commandData = await this.getGeneralChartData(item.componentCommandData).catch(() => {
          this.$set(item, "isLoading", false);
          console.error("拉取失败提醒");
        });
        this.$set(item, "isLoading", false);
      }

      //windowMedia，windowGeneralChart和windowCustomChart是互斥的，只能三选一
      await this.otherWindowClose();

      if (componentCommand == "show") {
        this.activeWindowGeneralChartName = item.name;
        this.sendCommand(item.componentName, item.componentCommand, commandData);
      } else {
        this.activeWindowGeneralChartName = "";
        this.sendCommand(item.componentName, "hide");
      }
    },
    //获取通用图表的数据
    getGeneralChartData(url) {
      return new Promise(async (resolve, reject) => {
        let chartData = await axios.get(url);
        try {
          if (chartData.data.dataDescrip == undefined) {
            reject();
          } else {
            chartData = this.changeChartType(chartData.data);
            console.log("chartData", chartData);
            const data = convertChartData(chartData.dataDescrip);
            const result = {
              title: chartData.dataDescrip.chartTitle,
              data: data,
              type: chartData.dataDescrip.chartType,
            };
            resolve(result);
          }
        } catch (e) {
          reject();
        }
      });
    },
    //潜规则：通过标题，或者数据量，改变图表类型
    changeChartType(data) {
      if (data.dataDescrip.chartType == "柱形图" && data.dataDescrip.chartTitle.indexOf("区间柱图") > -1) {
        data.dataDescrip.chartType = "区间柱图";
        data.dataDescrip.chartTitle = data.dataDescrip.chartTitle.replace("区间柱图", "");
      } else if (data.dataDescrip.chartType == "柱形图" && data.dataDescrip.chartTitle.indexOf("方块图") > -1) {
        data.dataDescrip.chartType = "方块图";
        data.dataDescrip.chartTitle = data.dataDescrip.chartTitle.replace("方块图", "");
      } else if (data.dataDescrip.chartType == "柱形图" && data.dataDescrip.originalData.chart1Ydata.length > 1) {
        data.dataDescrip.chartType = "多柱图";
      }
      if (data.dataDescrip.chartType == "饼图" && data.dataDescrip.chartTitle.indexOf("玫瑰图") > -1) {
        data.dataDescrip.chartType = "玫瑰图";
        data.dataDescrip.chartTitle = data.dataDescrip.chartTitle.replace("玫瑰图", "");
      }
      return data;
    },
    //把多柱图从柱形图里摘出来
    getChartType(data) {
      if (data.chartType == "柱形图" && data.originalData.chart1Ydata.length > 1) {
        return "多柱图";
      } else {
        return data.chartType;
      }
    },
    //设置当前window媒体-包括图片和视频
    async setWindowMedia(item, componentCommand) {
      await this.otherWindowClose();

      this.activeWindowMediaName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeWindowMediaName = "";
      }
    },
    //设置当前window iframe
    async setWindowIframe(item, componentCommand) {
      await this.otherWindowClose();

      this.activeWindowIframeName = item.name;
      this.sendCommand(item.componentName, componentCommand, item.componentCommandData);
      if (componentCommand == "hide") {
        this.activeWindowIframeName = "";
      }
    },
    //windowMedia，windowGeneralChart和windowCustomChart是互斥的，只能三选一
    async otherWindowClose() {
      return new Promise(async (resolve, reject) => {
        if (this.activeWindowMediaName != "") {
          this.sendCommand("windowMedia", "hide");
          this.activeWindowMediaName = "";
          await sleep(400);
          resolve();
        }
        if (this.activeWindowIframeName != "") {
          this.sendCommand("windowIframe", "hide");
          this.activeWindowIframeName = "";
          await sleep(400);
          resolve();
        }
        if (this.activeWindowGeneralChartName != "" || this.activeWindowCustomChartName != "") {
          this.sendCommand("chartSwitcher", "hide");
          this.activeWindowGeneralChartName = "";
          this.activeWindowCustomChartName = "";
          await sleep(400);
          resolve();
        }
        resolve();
      });
    },

    //发送命令,统一格式为组件名，命令，命令数据
    sendCommand(componentName, componentCommand, componentCommandData) {
      chatSocket.send(
        JSON.stringify({
          componentName: componentName,
          componentCommand: componentCommand,
          componentCommandData: componentCommandData,
        })
      );
    },
    //接收消息
    startMonite() {
      chatSocket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        //如果收到了背景上线消息，就把询问时间改大，同时开始计时，15秒后如果没收到，就认为背景下线了
        if (data.eventType == "backOnline") {
          this.backAskGap = 12 * 1000;
          clearTimeout(this.backCoolTimeOut);
          this.backCoolTimeOut = setTimeout(() => {
            this.isBackConnected = false;
          }, 15 * 1000);
        }

        //接收端上线后把控制端当前状态全部发过去
        if (data.eventType == "firstAnswer") {
          this.isReceiveConnected = true;
          this.isBackConnected = true;
          this.sendBundleCommand();
          //进入心跳询问
          this.repeatTimestamp = new Date();
          this.repeatAskBackOnline(this.repeatTimestamp);
        }

        //预览端上线后把控制端当前状态全部发过去
        if (data.eventType == "previewFirstAnswer") {
          this.sendBundleCommand();
        }
      };
    },
    //发送控制端的所有状态
    sendBundleCommand() {
      this.sendBg();
      this.sendLogo();
      // this.sendMarketMarquee();
      // this.sendTextMarquee();
      this.sendMultiFuncBar();
      this.sendEffect();
      this.sendWindow();
      this.sendScreenMode();
    },
    //设置屏幕模式，横屏还是竖屏
    sendScreenMode() {
      this.sendCommand("screenMode", "", this.activeScreenModeName);
    },
    sendBg() {
      this.broadcastBgMediaList.map((item) => {
        if (item.name == this.activeBroadcastBgMediaName) {
          this.setBroadcastBgMedia(item, "show");
        }
      });
    },
    sendLogo() {
      if (!this.activeLogoName) {
        this.setLogo(this.logoList[0], "hide");
        return;
      }
      this.logoList.map((item) => {
        if (item.name == this.activeLogoName) {
          this.setLogo(item, "show");
        }
      });
    },
    sendMarketMarquee() {
      if (!this.activeMarqueeName) {
        this.setMarquee(this.marqueeList[0], "hide");
        return;
      }
      this.marqueeList.map((item) => {
        if (item.name == this.activeMarqueeName) {
          this.setMarquee(item, "show");
        }
      });
    },
    sendMultiFuncBar() {
      if (!this.activeMultiFuncBarName) {
        this.setMultiFuncBar(this.multiFuncBarList[0], "hide");
        return;
      }
      this.multiFuncBarList.map((item) => {
        if (item.name == this.activeMultiFuncBarName) {
          this.setMultiFuncBar(item, "show");
        }
      });
    },
    sendTextMarquee() {
      if (!this.activeTextMarqueeName) {
        this.setMarquee(this.textMarqueeList[0], "hide");
        return;
      }
      this.textMarqueeList.map((item) => {
        if (item.name == this.activeTextMarqueeName) {
          this.setTextMarquee(item, "show");
        }
      });
    },
    sendEffect() {
      if (!this.activeEffectName) {
        this.setMarquee(this.effectList[0], "hide");
        return;
      }
      this.effectList.map((item) => {
        if (item.name == this.activeEffectName) {
          this.setEffect(item, "show");
        }
      });
    },
    sendWindow() {
      if (!this.activeWindowCustomChartName && !this.activeWindowGeneralChartName && !this.activeWindowMediaName) {
        this.setCustomWindowChart(this.windowCustomChartList[0], "hide");
        this.setGeneralWindowChart(this.windowGeneralChartList[0], "hide");
        this.setWindowMedia(this.windowMediaList[0], "hide");
        return;
      }
      if (this.activeWindowCustomChartName) {
        this.windowCustomChartList.map((item) => {
          if (item.name == this.activeWindowCustomChartName) {
            this.setCustomWindowChart(item, "show");
          }
        });
      } else if (this.activeWindowGeneralChartName) {
        this.windowGeneralChartList.map((item) => {
          if (item.name == this.activeWindowGeneralChartName) {
            this.setGeneralWindowChart(item, "show");
          }
        });
      } else if (this.activeWindowMediaName) {
        this.windowMediaList.map((item) => {
          if (item.name == this.activeWindowMediaName) {
            this.setWindowMedia(item, "show");
          }
        });
      }
    },
    async initObs() {
      //连接本地obs
      obs.connect({
        address: "127.0.0.1:4444",
        // password: "mask2012"
      });

      //连接obs成功后的基操勿6
      obs.on("ConnectionOpened", () => {
        //拉虚拟摄像头状态
        this.getVirtualCamStatus();
        //拉录像状态
        this.getRecordingStatus();
        //拉配置文件列表
        this.getProfileList();
        //拉场景列表
        this.getSceneList();
      });
    },
    getProfileList() {
      obs.send("ListProfiles").then(async (data) => {
        // console.log("ListProfiles", data);
        const currentProfile = await this.getCurrentProfile();
        this.profileList = data.profiles;
        this.profileList.map((item) => {
          if (item["profile-name"] == currentProfile) {
            item.isCurrent = true;
          }
        });
      });
    },
    switchProfile(profileName) {
      obs.send("SetCurrentProfile", { "profile-name": profileName }).then((data) => {
        if (data.status == "ok") {
          this.profileList.map((item) => {
            this.$set(item, "isCurrent", item["profile-name"] == profileName);
          });
        }
      });
    },
    getCurrentProfile() {
      return new Promise((resolve, reject) => {
        obs.send("GetCurrentProfile").then((data) => {
          resolve(data.profileName);
        });
      });
    },
    getSceneList() {
      obs.send("GetSceneList").then((data) => {
        this.sceneList = data.scenes;
        this.sceneList.map((item) => {
          if (item.name == data.currentScene) {
            item.isCurrent = true;
          }
        });
      });
    },
    switchScene(sceneName) {
      obs.send("SetCurrentScene", { "scene-name": sceneName }).then((data) => {
        if (data.status == "ok") {
          this.sceneList.map((item) => {
            this.$set(item, "isCurrent", item.name == sceneName);
          });
        }
      });
    },
    getRecordingStatus() {
      obs.send("GetRecordingStatus").then((data) => {
        if (data.status == "ok") {
          this.isRecording = data.isRecording;
        }
      });
    },
    toggleRecord() {
      if (!this.isRecording) {
        this.startRecord();
      } else {
        this.stopRecord();
      }
    },
    startRecord() {
      obs.send("StartRecording").then((data) => {
        if (data.status == "ok") {
          this.isRecording = true;
        }
      });
    },
    stopRecord() {
      obs.send("StopRecording").then((data) => {
        if (data.status == "ok") {
          this.isRecording = false;
        }
      });
    },
    toggleVirtual() {
      if (!this.isVirtualing) {
        this.startVirtual();
      } else {
        this.stopVirtual();
      }
    },
    getVirtualCamStatus() {
      obs.send("GetVirtualCamStatus").then((data) => {
        if (data.status == "ok") {
          this.isVirtualing = data.isVirtualCam;
        }
      });
    },
    startVirtual() {
      obs.send("StartVirtualCam").then((data) => {
        if (data.status == "ok") {
          this.isVirtualing = true;
        }
      });
    },
    stopVirtual() {
      obs.send("StopVirtualCam").then((data) => {
        if (data.status == "ok") {
          this.isVirtualing = false;
        }
      });
    },
    obsControlTest() {
      obs.send("GetSceneItemList").then((data) => {
        console.log("GetSceneItemList", data);
      });
    },
    obsControlTest2() {
      obs.send("GetSceneItemProperties", { "scene-name": "bp", item: { id: 5 } }).then((data) => {
        console.log("GetSceneItemProperties", data);
      });
    },
    obsControlTest3() {
      obs.send("GetSourceFilters", { sourceName: "媒体源" }).then((data) => {
        console.log("GetSourceFilters", JSON.stringify(data.filters[0]));
      });
    },
    obsControlTest4() {
      obs.send("GetSourceSettings", { sourceName: "浏览器" }).then((data) => {
        console.log("GetSourceSettings", data);
      });
    },
    obsControlTest5() {
      obs.send("RefreshBrowserSource", { sourceName: "浏览器" }).then((data) => {
        console.log("RefreshBrowserSource", data);
      });
    },

    async previewObs() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      function gotStream(stream) {
        const videoElement = document.querySelector("#previewVideo");
        videoElement.srcObject = stream;
      }

      function handleError(error) {
        console.log("navigator.MediaDevices.getUserMedia error: ", error.message, error.name);
      }

      const devices = await navigator.mediaDevices.enumerateDevices();
      let obsId = "";
      // console.log("devices", devices);
      devices.map((item) => {
        if (item.label == "OBS Virtual Camera") {
          obsId = item.deviceId;
        }
      });
      if (obsId == "") {
        alert("obs 未打开!");
      }
      const constraints = {
        video: { deviceId: obsId ? { exact: obsId } : undefined },
      };
      // console.log("constraints", constraints);
      navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(handleError);
    },
    async initDraw() {
      await sleep(1000);
      this.drawOptions.canvasWidth = this.$refs.refPreviewVideoHolder.clientWidth;
      this.drawOptions.canvasHeight = this.$refs.refPreviewVideoHolder.clientHeight;
      this.drawOptions.writeColor = this.colorArr[0];
    },
  },
  mounted() {
    //个性化图表包含的每个组件
    this.windowCustomChartList.push(marketChart);
    this.windowCustomChartList.push(northFundChart);
    this.windowCustomChartList.push(auctionChart);
    this.windowCustomChartList.push(financeCompareChart);
    this.windowCustomChartList.push(marginTradeChart);
    this.windowCustomChartList.push(diagnosisValueChart);
    this.windowCustomChartList.push(financeAnalysisChart);
    this.windowCustomChartList.push(evaluatingDistChart);
    this.windowCustomChartList.push(longHuChart);
    this.windowCustomChartList.push(ipoInfoChart);
    this.windowCustomChartList.push(checkMarketChart);
    this.windowCustomChartList.push(bigTradeChart);
    this.windowCustomChartList.push(chipDistChart);
    this.windowCustomChartList.push(resultsContrastChart);
    this.windowCustomChartList.push(companyIntroChart);
    this.windowCustomChartList.push(plateAreaChart)
    this.windowCustomChartList.push(intelligentDiagnosisChart)
    this.windowCustomChartList.push(investmentMapChart)
    this.windowCustomChartList.push(duPonChart)
    this.windowCustomChartList.push(stocksFundsChart)
    this.windowCustomChartList.push(sharePledgeChart)
    this.windowCustomChartList.push(bsSignalChart)
    this.windowCustomChartList.push(keChuangGraphChart)
    this.windowCustomChartList.push(plateQuoteChart)
    this.windowCustomChartList.push(nuggetsChart)
    this.windowCustomChartList.push(stockMineSweepChart)
    this.windowCustomChartList.push(financingTrackChart)
    this.windowCustomChartList.push(goodwillAnalysisChart)
    this.windowCustomChartList.push(goodwillWarnChart)
    this.windowCustomChartList.push(historyBackChart)
    this.windowCustomChartList.push(longHuStockChart)
    this.windowCustomChartList.push(similarKChart)
    this.windowCustomChartList.push(intelliChooseStockChart)
    this.windowGeneralChartList = windowGeneralChartList;

    //个性化图表包含的每个组件的标题
    this.windowCustomChartListTitles = [
      "市场",
      "北上资金",
      "集合竞价",
      "财务对比",
      "融资融券",
      "诊股-价值",
      "财务业绩",
      "估值系列",
      "龙虎榜",
      "新股中心",
      "市场强度",
      "大宗交易",
      "筹码分布",
      "财报对比图",
      "个股F10",
      "板块地域概念",
      "个股诊断",
      "投资图谱",
      "杜邦分析",
      "个股资金",
      "股权质押",
      "多空信号",
      "科创掘金",
      "榜单",
      "定增掘金",
      "扫雷",
      "融资追击",
      "商誉分析（大盘）",
      "商誉分析(个股)",
      "历史回看",
      "龙虎榜(个股)",
      "相似K线",
      "策略选股",
    ];

    this.userId = this.$route.query.userId;
    if (this.userId == undefined) {
      console.error("未指定userId");
      return;
    }
    this.createSocket();

    //开始接管obs
    this.initObs();

    //开始预览obs画面
    this.previewObs();

    //开始绘图
    this.initDraw();
  },
};
</script>

<style lang="less" scoped>
@import url("index.less");
</style>
